import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import Header from "../components/header";
import Layout from "../components/layout";
import { Seo } from "../components/seo";
import "../components/blog.css";

function Blog({ data }) {
  if (data.prismicBlog.lang === "en-us") {
    var currentUrl = "/";
    var relatedText = "Related articles";
    var latestText = "Latest articles";
    var showmoreText = "Show more articles";
    var nomoreText = "No more results";
  } else if (data.prismicBlog.lang === "et-ee") {
    currentUrl = "/et/";
    relatedText = "Sarnased artiklid";
    latestText = "Hiljutised artiklid";
    showmoreText = "Näita veel";
    nomoreText = "Tulemused puuduvad";
  } else {
    currentUrl = "/";
    relatedText = "Related articles";
    latestText = "Latest articles";
    showmoreText = "Show more articles";
    nomoreText = "No more results";
  }

  if (data.prismicBlog.lang === "en-us") {
    var NextUrl = "/blog/";
  } else if (data.prismicBlog.lang === "et-ee") {
    NextUrl = "/et/blogi";
  } else {
    NextUrl = "wrong lang";
  }

  // Array of all news articles
  var allNews = data.allPrismicBlog.edges;
  var newsByCategory = {
    Dagnews: [],
    Cryptonews: [],
    Blog: [],
  };
  for (var i = 0; i < allNews.length; i++) {
    var category = allNews[i].node.data.category;
    newsByCategory[category].push({ node: allNews[i].node });
  }
  // State for the list
  const [list, setList] = useState([...allNews.slice(0, 3)]);
  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false);
  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allNews.length > 3);
  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < allNews.length;
      const nextResults = isMore
        ? allNews.slice(currentLength, currentLength + 3)
        : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]); //eslint-disable-line
  //Check if there is more
  useEffect(() => {
    const isMore = list.length < allNews.length;
    setHasMore(isMore);
  }, [list]); //eslint-disable-line
  if (data.prismicBlog.data.category === "Blog") {
    category = "Dagblog";
  } else {
    category = data.prismicBlog.data.category;
  }

  return (
    <Layout htmllang={data.prismicBlog.lang}>
      <Header htmllang={data.prismicBlog.lang} langswitcher={NextUrl} />
      <div className="single-post-page">
        <div className="post-wrap">
          <div className="post-above">
            <div className="post-first">
              <p>
                <strong>{data.prismicBlog.data.date}</strong> |{" "}
                <span>#{category}</span>
              </p>
              <h1>{data.prismicBlog.data.title.text}</h1>
              {/* <div className="post-author">
                <div className="auth-img">
                  <img src={data.prismicBlog.data.featured_image.url} />
                </div>

                <div className="auth-content">
                  <h3>Dagcoin</h3>
                </div>
              </div> */}
            </div>

            <div className="above-image">
              <img
                alt={data.prismicBlog.data.title.text}
                src={data.prismicBlog.data.featured_image.url}
              />
            </div>
          </div>
          <div className="post-content">
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicBlog.data.blog_content.html,
              }}
            />
          </div>
        </div>
        <div className="single-post-footer">
          <div className="blog-grid row related-single">
            <h5 className="subtitle-blue">{relatedText}</h5>
            <div className="row">
              {newsByCategory.Blog.slice(3, 6).map((value, index) => {
                return (
                  <div className="single-post-custom" key={index}>
                    <Link to={currentUrl + value.node.uid}>Link</Link>
                    <div className="col-sm-6">
                      <div className="post-thumb">
                        <img
                          alt={value.node.data.title.text}
                          src={value.node.data.featured_image.url}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <h6>{value.node.data.title.text}</h6>
                      <div className="single-text-bottom">
                        <p>{value.node.data.blog_content.text}</p>
                      </div>
                      <div className="category-post">
                        #{value.node.data.category}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="blog-grid row single-latest">
            <h5 className="subtitle-blue">{latestText}</h5>
            <div className="row">
              {list.map((value, index) => {
                return (
                  <div
                    className="single-post-custom latest-single-product"
                    key={index}
                  >
                    <Link to={currentUrl + value.node.uid}>Link</Link>

                    <div className="col-sm-6">
                      <div className="post-thumb">
                        <img
                          alt={value.node.data.title.text}
                          src={value.node.data.featured_image.url}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <h6>{value.node.data.title.text}</h6>
                      <div className="single-text-bottom">
                        <p>{value.node.data.blog_content.text}</p>
                      </div>
                      <div className="category-post">
                        #{value.node.data.category}
                      </div>
                    </div>
                  </div>
                );
              })}

              {hasMore ? (
                <button className="loadmore" onClick={handleLoadMore}>
                  {showmoreText}
                </button>
              ) : (
                <p className="nomoreresults">{nomoreText}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const Head = ({ data }) => {
  const { seo_title, seo_description, title } = data.prismicBlog.data;
  return (
    <>
      <Seo
        description={seo_description.text}
        // If the blog has a description from Prismic use it. If it doesn't use the blog's content
        pathname={data.prismicBlog.uid}
        title={seo_title.text || title.text}
        // lang={data.prismicPrivacyPolicy.lang}
      />
    </>
  );
};

export const query = graphql`
  query SingleBlogPostQuery($id: String, $lang: String) {
    prismicBlog(id: { eq: $id }, lang: { eq: $lang }) {
      id
      lang
      uid
      alternate_languages {
        id
        lang
        type
        uid
      }
      data {
        category
        title {
          text
        }
        blog_content {
          html
          text
        }
        seo_description {
          text
        }
        seo_title {
          text
        }
        featured_image {
          url
        }
        category
        date(formatString: "DD MMMM, YYYY", locale: "en")
      }
    }
    dagcoinprofileimage: file(relativePath: { eq: "dagcoin-profile.png" }) {
      childImageSharp {
        gatsbyImageData(width: 480, quality: 50, layout: CONSTRAINED)
      }
    }
    allPrismicBlog(
      sort: { fields: data___date, order: DESC }
      filter: { lang: { eq: $lang } }
    ) {
      edges {
        node {
          data {
            blog_content {
              text
            }
            category
            date(formatString: "D.M.Y")
            featured_image {
              url
            }
            title {
              text
            }
          }
          uid
        }
      }
    }
  }
`;
export default Blog;
